import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Translator from "../../languages/Translator";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

export default function ViewReceipt() {
	let { receipt } = useParams();
	const [filePDF, setFilePDF] = useState(null);
	const [fileUrl, setFileUrl] = useState(null);

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	useEffect(() => {
		GetReceiptPDF();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const GetReceiptPDF = () => {
		const url = `${process.env.REACT_APP_API_BASE_URL}/direceipt/pdf?urlUID=${receipt}`;
		const params = { method: "GET", responseType: "blob" };

		fetch(url, params)
			.then((response) => {
				if (response.ok) {
					return response.blob();
				}
				return Promise.reject(response);
			})
			.then((response) => {
				setFilePDF(response);
				setFileUrl(URL.createObjectURL(response));
			})
			.catch(() => {
				alert("Impossible to open file");
			});
	};

	const downloadPdf = () => {
		window.open(fileUrl);
	};

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	return (
		<>
			<div className="Receipt-Buttons">
				<div className={fileUrl ? "Receipt-Download-PDF" : "Receipt-Download-PDF-disabled"} onClick={fileUrl ? downloadPdf : null}>
					{Translator("app.receipt.download.pdf", "Download PDF")}
				</div>
			</div>

			{fileUrl ? (
				<div className="pdf-container">
					<Document file={filePDF} className="pdf" onLoadSuccess={onDocumentLoadSuccess}>
						<Page pageNumber={pageNumber} />
					</Document>
					<div className="pagination">
						<button disabled={pageNumber === 1} onClick={() => setPageNumber(() => pageNumber - 1)}>
							{Translator("app.receipt.prev_page", "Prev page")}
						</button>
						<button disabled={pageNumber === numPages} onClick={() => setPageNumber(() => pageNumber + 1)}>
							{Translator("app.receipt.next_page", "Next page")}
						</button>
					</div>
					<p>
						{Translator("app.receipt.page", "Page")} {pageNumber} {Translator("app.receipt.of", "of")} {numPages}
					</p>
				</div>
			) : (
				<div className="loader-container">
					<div className="loader"></div>
				</div>
			)}
		</>
	);
}
