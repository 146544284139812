import React from 'react'
import Translator from '../../languages/Translator'

export default function viewHome() {
	
    return (
        <div>
            <h1>
                {Translator('app.home.welcome', 'Welcome to the Digital Receipt App!')}
            </h1>
        </div>
    )
}
