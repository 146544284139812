import React from 'react'
import Translator from '../../languages/Translator'

export default function ViewNotFound() {
    return (
        <div>
            <h1>{Translator('app.notfound.line1', 'Error 404!')}</h1><br/>
            {Translator('app.notfound.line2', 'The page you\'re looking for does not exist.')}
        </div>
    )
}
