import React, { useState } from "react";
import { IntlProvider } from "react-intl";

// Import all the translations files in the languages folder
const jsonList = require.context("../languages", true, /.json$/);
const languages = {};
const languagesList = [];

jsonList.keys().forEach((key) => {
	const fileName = key.replace("./", "");
	const resource = require(`../languages/${fileName}`);
	const namespace = fileName.replace(".json", "");
	languagesList.push(namespace);
	languages[namespace] = JSON.parse(JSON.stringify(resource));
});

var currentLocale = navigator.language.split(/[-_]/)[0]; // language without region code
if (languagesList.indexOf(currentLocale) === -1) currentLocale = "en"; // default to 'en' if not listed

export default function TranslateProvider(props) {
	// eslint-disable-next-line no-unused-vars
	const [locale, setLocale] = useState(currentLocale);

	// const changeLang = (getLang) => {
	// 	setLocale(getLang);
	// }

	return (
		<IntlProvider locale={locale} messages={languages[locale]}>
			{/* <div className="App-Language">
				<select onChange={e => changeLang(e.target.value)} defaultValue={locale}>
				{
					languagesList.map((lang) =>
						<option key={'app.language.'+lang} value={lang}>
							{lang.toUpperCase()}
						</option>
					)
				}
				</select>
			</div> */}
			{props.children}
		</IntlProvider>
	);
}
