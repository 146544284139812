import React from "react";
import { BrowserRouter as Router, Routes as R, Route } from "react-router-dom";
import ViewHome from "./views/ViewHome/ViewHome";
import ViewNotFound from "./views/ViewNotFound/ViewNotFound";
import ViewReceipt from "./views/ViewReceipt/ViewReceipt";

export default function Routes() {
	return (
		<Router>
			<R>
				<Route exact path="/" element={<ViewHome />}></Route>
				<Route path="/receipt/:receipt" element={<ViewReceipt />}></Route>
				<Route element={<ViewNotFound />} />
			</R>
		</Router>
	);
}
